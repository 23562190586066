import * as Sentry from '@sentry/react';

export const captureExceptionWithMessage = (message: string, error?: unknown): void => {
  Sentry.captureMessage(message, 'error');
  if (error) {
    /* 
    It is recommended that captureException 
    receives an instance of Error or some object 
    that extends it to properly log the information 
    */
    const parsedError: Error =
      error instanceof Error ? error : new Error(JSON.stringify(error));
    Sentry.captureException(parsedError);
  }

  console.error(message, error);
};

export const Logger = {
  captureExceptionWithMessage,
  error: captureExceptionWithMessage,
};
