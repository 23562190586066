import { Tooltip, TooltipProps } from '@mantine/core';
import classNames from 'classnames';
import { clsx } from 'clsx';
import { Link } from 'react-router-dom';
import styles from './ClickableRegion.module.scss';

export interface ClickableRegionProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  forceAnchor?: boolean;
  href?: string;
  onClick?: (
    event:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  primaryClass?: string;
  target?: string;
  /** required, used for accessibility */
  regionLabel?: string | string[];
  tooltipDisabled?: boolean;
  tooltipPlacement?: TooltipProps['position'];
  tooltipMultiline?: TooltipProps['multiline'];
}

/**
 * A generic, accessibility-minded component meant for use whenever a more specific component isn't necessary
 * Returns either a button or anchor, based on context.
 * @visibleName ClickableRegion
 */
const ClickableRegion = ({
  children,
  className,
  disabled,
  forceAnchor,
  href,
  primaryClass = 'clickable-region',
  regionLabel,
  tooltipDisabled = false,
  tooltipPlacement,
  tooltipMultiline,
  ...rest
}: ClickableRegionProps) => {
  const regionClasses = classNames(
    primaryClass,
    className,
    // While buttons handle disabled automatically, other tags are passed a style
    disabled && styles.disabledRegion,
  );

  const isMultilineLabel = Array.isArray(regionLabel);
  const tooltipLabel = isMultilineLabel ? regionLabel.join('\n') : regionLabel;
  const titleLabel = isMultilineLabel ? regionLabel[0] : regionLabel;

  return (
    <Tooltip
      label={tooltipLabel}
      position={tooltipPlacement}
      disabled={tooltipDisabled || !regionLabel}
      multiline={tooltipMultiline || isMultilineLabel}
      classNames={{ tooltip: styles.multilineTooltip }}
    >
      {href || forceAnchor ? (
        !forceAnchor &&
        !href?.includes('http://') &&
        !href?.includes('https://') &&
        href !== '#' ? (
          <Link
            aria-label={titleLabel}
            className={regionClasses}
            title={tooltipDisabled ? titleLabel : undefined}
            to={href ?? ''}
            onClick={(e) => {}}
            {...rest}
          >
            {children}
          </Link>
        ) : (
          <a
            aria-label={titleLabel}
            className={regionClasses}
            href={href}
            rel="noopener noreferrer"
            title={tooltipDisabled ? titleLabel : undefined}
            {...rest}
          >
            {children}
          </a>
        )
      ) : (
        <button
          aria-disabled={disabled}
          aria-label={titleLabel} // This should be normal speech - no HTML, etc.
          className={clsx(styles.clickableRegionButton, regionClasses)}
          disabled={disabled}
          title={tooltipDisabled ? titleLabel : undefined}
          type="button"
          {...rest}
        >
          {children}
        </button>
      )}
    </Tooltip>
  );
};

export default ClickableRegion;
