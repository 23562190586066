import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { FeatureFlag } from '../configs/featureFlags';

export const getNormalizedCookieName = (discriminator: string) =>
  `experimental_${discriminator}`;

const useFeatureFlag = (featureFlagName: FeatureFlag): boolean => {
  const [hasCookie, setHasCookie] = useState(false);

  useEffect(() => {
    const checkCookie = (): void => {
      const value = Cookies.get(getNormalizedCookieName(featureFlagName));
      setHasCookie(value === 'on');
    };

    checkCookie();
  }, [featureFlagName]);

  return hasCookie;
};

export default useFeatureFlag;
