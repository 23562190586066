import { Badge, Title, Tooltip } from '@mantine/core';
import { MRT_PaginationState } from 'mantine-react-table';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ProductsTable } from '../../components/DataTables/ProductsTable/ProductsTable';
import { PaginationInfo, useFetchProducts } from '../../hooks/products/useFetchProducts';
import { useProductsFilter } from '../../hooks/products/useProductsFilter';
import { useURLandLocalStorageSortingState } from '../../hooks/utils/usePersistentStates';

export const Products = () => {
  const { t } = useTranslation();

  const productsFilterHook = useProductsFilter();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [sorting, setSorting] = useURLandLocalStorageSortingState('products', [
    {
      id: 'dateModified',
      desc: true,
    },
  ]);

  const {
    isFetching,
    isLoading,
    data: productsResponse = { data: [], queryInfo: {} },
  } = useFetchProducts({
    sortColumn: sorting[0]?.id,
    sortType:
      sorting[0]?.desc === true ? 'desc' : sorting[0]?.desc === false ? 'asc' : undefined,
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    filters: productsFilterHook.getCurrentFilters(),
  });

  return (
    <section className="page-products anim-slideInUpShort">
      <Helmet title={t('page.products.page-title')}>
        <meta name="description" content={t('app.oneliner')} />
      </Helmet>
      <Title mb="sm" order={1} className="anim-slideInDownShort">
        <span>{t('page.products.page-title')}</span>
        <Tooltip label={t('page.products.beta-tooltip')} width={250} multiline>
          <Badge className="beta-chip">{t('global.beta')}</Badge>
        </Tooltip>
      </Title>
      <ProductsTable
        data={productsResponse?.data}
        queryInfo={productsResponse?.queryInfo as PaginationInfo}
        isLoading={isLoading}
        isFetching={isFetching}
        productsFilterHook={productsFilterHook}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
      />
    </section>
  );
};
