import '../../Labels/Labels.scss';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Flex,
  Stack,
  Text,
  Divider,
  Tooltip,
  Title,
  MultiSelect,
  Slider,
  CloseButton,
} from '@mantine/core';
import Icon from '../../Icon/Icon';
import { ProductsFilters, UpdateFilter } from '../../../hooks/products/useProductsFilter';
import { useFetchProductsCVEIDs } from '../../../hooks/products/useFetchProductsCVEIDs';
import { forwardRef } from 'react';
import { useFetchLabels } from '../../../hooks/queries/useFetchLabels';

interface Props {
  filters: ProductsFilters;
  updateFilter: UpdateFilter;
}

export const ProductsTableFilters = ({ filters, updateFilter }: Props) => {
  const { t } = useTranslation();

  const { data: componentCveIds = [] } = useFetchProductsCVEIDs();
  const { data: labelsOptions = [] } = useFetchLabels({});

  const { hasVulns, hasCriticalVulns, recommendation, cveIds, cvssScore, labels } =
    filters;

  return (
    <>
      {/*SUGGESTED*/}
      <Title
        order={3}
        className="cap-letter-spacing"
        color="cyan"
        size="lg"
        mt="0"
        mb="sm"
      >
        {t('tables.products.filters.assets')}
      </Title>
      <Divider my={0} />
      <Stack my="md">
        <MultiSelect
          clearable
          searchable
          label={t('tables.assets.filters.labels')}
          placeholder={t('global.select')}
          data={labelsOptions.map((label) => ({
            color: label.color,
            label: label.name!,
            value: label._id?.toString()!,
          }))}
          itemComponent={forwardRef<HTMLDivElement, any>(
            ({ label, color, ...rest }, ref) => (
              <Flex ref={ref} {...rest}>
                <div className="label-color-container">
                  <div className="label-color" style={{ backgroundColor: color }} />
                </div>
                <div className="label-name">{label}</div>
              </Flex>
            ),
          )}
          valueComponent={forwardRef<HTMLDivElement, any>(
            ({ label, color, onRemove, ...rest }, ref) => (
              <Flex ref={ref} {...rest}>
                <div className="label-color-container">
                  <div className="label-color" style={{ backgroundColor: color }} />
                </div>
                <div className="label-name">{label}</div>
                <CloseButton
                  onMouseDown={onRemove}
                  variant="transparent"
                  size={22}
                  iconSize={14}
                  tabIndex={-1}
                />
              </Flex>
            ),
          )}
          value={labels}
          onChange={(value) => updateFilter('labels', value)}
          rightSection={labels?.length ? null : undefined} //show clear button
        />
      </Stack>
    </>
  );
};
