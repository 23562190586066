import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { Organization } from '../../types/organization.interface';

interface Params {
  organizationId?: string | null;
}

interface Options {
  staleTime?: number;
}

export const useFetchOrganization = ({ organizationId }: Params, options?: Options) => {
  const fetchUrl = `organization/${organizationId || ''}`;

  return useQuery({
    enabled: Boolean(organizationId),
    queryKey: ['currentOrganization', { _id: organizationId }, `/${fetchUrl}`],
    queryFn: () => AxiosProxy.get({ url: fetchUrl }),
    select: (data) => data?.data?.[0] as Organization,
    onError: (error) => {
      captureExceptionWithMessage(`Unable to fetch user: `, error);
    },
    staleTime: options?.staleTime,
  });
};
