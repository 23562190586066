import { ManifestApiResponse } from '@manifest-cyber/types/interface/apiResponse';
import {
  AssetRiskScore,
  VulnerabilityRiskAction,
} from '@manifest-cyber/types/interface/db';
import {
  InterfaceOrganizationAsset,
  InterfaceOrganizationComponent,
  InterfaceVulnerability,
} from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import capitalizeWords from '../../lib/capitalizeWords';
import { rejectFailedRequestOrEmptyData } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { TransformedToClientTypes } from '../../types/transformedToClientType';

export type GlobalSearchResponse = Omit<
  ManifestApiResponse<GlobalSearchData>,
  'queryInfo'
> & {
  queryInfo: {
    page: number;
    limit: number;
    totalReturn: number;
    totalCount: number;
    isGrouped: boolean;
    productsLength: number;
    assetsLength: number;
    componentsLength: number;
    vulnerabilitiesLength: number;
  };
};

export interface GlobalSearchData {
  assets: TransformedToClientTypes<InterfaceOrganizationAsset>[];
  components: TransformedToClientTypes<InterfaceOrganizationComponent>[];
  vulnerabilities: {
    fields: TransformedToClientTypes<InterfaceVulnerability> & {
      cvssScore?: number | null;
      severity?: string | null;
      preferredScore?: number | null;
      preferredSeverity?: string | null;
    };
    _id: string;
    score: number;
  }[];
  products?: { _id: string; version: string; name: string }[];
}

type CapitalizedVulnerabilityRiskAction = Capitalize<VulnerabilityRiskAction>;
type VulnerabilitySeverity = 'CRITICAL' | 'HIGH' | 'MEDIUM' | 'LOW';

export interface GlobalSearchMappedata {
  assets: {
    id: string;
    totalVulns: number;
    name: string;
    version: string;
    coordinates: string;
    riskScore?: AssetRiskScore;
  }[];
  components: {
    id: string;
    descriptionToDisplay: string;
    ecosystemToDisplay: string;
    name: string;
    version: string;
  }[];
  vulnerabilities: {
    id: string;
    cveId: string;
    recommendedActionToDisplay: CapitalizedVulnerabilityRiskAction | undefined;
    cvss3BaseScore?: number;
    cvss3BaseSeverity?: string;
    cvss2BaseScore?: number;
    cvss2BaseSeverity?: string;
    cvssScore?: number;
    severity?: VulnerabilitySeverity;
    preferredScore?: number;
    preferredSeverity?: string;
  }[];
  products: { name: string; version: string; id: string }[];
}

const extractAndConvertDateTimeToUTC = (targetText: string = '') => {
  const regExp =
    /[A-Za-z]{3}\s[A-Za-z]{3}\s\d{2}\s\d{4}\s\d{2}:\d{2}:\d{2}\sGMT[+-]\d{4}\s\([A-Za-z\s]+\)/gm;

  const extractedDateTimeText = targetText.match(regExp)?.[0];

  const parsedText = extractedDateTimeText
    ? targetText.replace(
        extractedDateTimeText,
        DateTime.fromISO(new Date(extractedDateTimeText).toISOString(), {
          zone: 'utc',
        }).toFormat("MMM dd yyyy HH:mm:ss 'UTC'"),
      )
    : targetText;

  return parsedText;
};

export const mapGlobalSearchResults = (data: GlobalSearchData): GlobalSearchMappedata => {
  const mapped = {
    ...data,
    components: data.components.map((component) => ({
      name: component.name,
      id: component._id?.toString() ?? '',
      version: component.version ? `@${component.version}` : '',
      descriptionToDisplay:
        (component.description?.substring(0, 50) || component.coordinates) ?? '',
      ecosystemToDisplay: (component.ecosystem || component.derivedEcosystem) ?? '',
    })),
    products:
      data.products?.map((product) => ({
        name: product.name ?? '',
        version: product.version ?? '',
        id: product._id?.toString() ?? '',
      })) || [],
    assets: data.assets.map((asset) => {
      const parsedVersion = extractAndConvertDateTimeToUTC(asset.version ?? '');
      return {
        riskScore: asset.riskScore ?? undefined,
        name: asset.name,
        id: asset._id?.toString() ?? '',
        version: parsedVersion !== 'NOASSERTION' ? `@${parsedVersion}` : '',
        coordinates: extractAndConvertDateTimeToUTC(asset.coordinates ?? ''),
        totalVulns: asset.countVulnerabilities?.total ?? 0,
      };
    }),
    vulnerabilities: data.vulnerabilities.map((vuln) => ({
      id: vuln.fields._id?.toString() ?? '',
      cveId: vuln.fields.cveId ?? '',
      recommendedActionToDisplay: vuln.fields.recommendedAction
        ? (capitalizeWords(
            vuln.fields.recommendedAction,
          ) as CapitalizedVulnerabilityRiskAction)
        : undefined,
      cvss3BaseScore: vuln.fields.cvss3BaseScore ?? undefined,
      cvss3BaseSeverity: vuln.fields.cvss3BaseSeverity ?? undefined,
      cvss2BaseScore: vuln.fields.cvss2BaseScore ?? undefined,
      cvss2BaseSeverity: vuln.fields.cvss2BaseSeverity ?? undefined,
      cvssScore: vuln.fields.cvssScore ?? undefined,
      severity: (vuln.fields.severity as VulnerabilitySeverity) ?? undefined,
      preferredScore: vuln.fields.preferredScore ?? undefined,
      preferredSeverity: vuln.fields.preferredSeverity ?? undefined,
    })),
  };
  return mapped;
};

const fetchSearchData = async (query: string) => {
  const response = (await AxiosProxy.get({
    url: 'search',
    params: {
      search_for: encodeURIComponent(query),
      grouped: true,
      limit: 4,
    },
  })) as GlobalSearchResponse;

  const successfulResponse = await rejectFailedRequestOrEmptyData(response);
  return mapGlobalSearchResults(successfulResponse.data);
};

const useFetchSearch = ({ query, enabled }: { query: string; enabled?: boolean }) => {
  return useQuery(['global-search', query], () => fetchSearchData(query), {
    enabled,
    staleTime: 0,
    refetchOnWindowFocus: false,
  });
};

export default useFetchSearch;
