import {
  GlobalSearchData,
  GlobalSearchResponse,
  mapGlobalSearchResults,
} from '../../components/ManifestSearch/useFetchSearch';

export const GLOBAL_SEARCH_RESPONSE_DATA: GlobalSearchData = {
  products: [
    {
      _id: '65a1a8382a43f7679f1a9851',
      name: 'npm product',
      version: '1.0',
    },
  ],
  assets: [
    {
      _id: '673bdb44b96e39d1100f2e71',
      organizationId: '6359f39ee9d7b7033b55bc23',
      packageUrlNoVersion: 'npm/cli',
      version: '10.5.0',
      authors: [],
      bomFormat: 'CycloneDX',
      bomFormatVersion: '1.6',
      changeDate: '2024-09-25T23:22:02.758Z',
      changeMade: true,
      commitVersionHash: null,
      containerName: null,
      coordinates: 'npm/cli@10.5.0',
      countLicenseIssues: {
        alert: 0,
        total: 1,
        unknown: 0,
        'AGPL-3.0-or-later': 1,
        Copyleft: 1,
      },
      countVulnerabilities: {
        critical: 12,
        high: 26,
        medium: 12,
        low: 0,
        info: 19,
        total: 69,
        mitigate: 13,
        monitor: 29,
        accept: 27,
        inKEV: 0,
        inKEVPreviously: 0,
      },
      cpes: [],
      dateCreated: '2024-11-19T00:26:44.699Z',
      dateModified: '2024-11-23T08:03:19.157Z',
      dependencyTree: null,
      derivedEcosystem: null,
      ecosystem: '',
      fullyQualifiedName: 'npm/cli',
      hasFlatHierarchyGraph: false,
      hasNamespace: false,
      hashes: [],
      imageId: null,
      isActive: true,
      isDeleted: false,
      isRemoteOssOrigin: null,
      isVulnerable: true,
      labelIds: [],
      lastVulnerabilityScan: null,
      licenseIds: [],
      licenses: [],
      metadata: null,
      name: 'npm/cli',
      newAssetUploadNotificationSent: false,
      nonTriagedCountVulnerabilities: {
        critical: 12,
        high: 26,
        medium: 12,
        low: 0,
        info: 19,
        total: 69,
        mitigate: 13,
        monitor: 29,
        accept: 27,
        inKEV: 0,
        inKEVPreviously: 0,
      },
      originatedFromEnrichedSbom: true,
      ossIngestId: '673bdcedf1b48961f86b52a5',
      packageGroup: '',
      packageUrl: 'npm/npm/cli@10.5.0',
      purlParts: null,
      references: [],
      relationshipToOrg: 'third',
      repoTags: null,
      resolvedCpes: [],
      riskScore: 'high',
      ticketIds: [],
      type: 'application',
      uploadByVendor: null,
      vendorName: [],
      vendorSourceSbom: null,
      versionMajor: 10,
      versionMinor: 5,
      versionPatch: 0,
      remoteOssOriginTarget: '',
    },
  ],
  components: [
    {
      isRemoteOssOrigin: null,
      remoteOssOriginTarget: '',
      _id: '657f3a6ac25f3e95939ce918',
      organizationId: '6359f39ee9d7b7033b55bc23',
      packageUrlNoVersion: 'pkg:npm/npm',
      version: '1.0.1',
      bomFormat: 'CycloneDX',
      bomFormatVersion: '1.4',
      commitVersionHash: null,
      containerName: null,
      coordinates: 'pkg:npm/npm@1.0.1',
      countLicenseIssues: {
        alert: 0,
        total: 0,
        unknown: 0,
      },
      countVulnerabilities: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
        info: 0,
        total: 0,
        mitigate: 0,
        monitor: 0,
        accept: 0,
        inKEV: 0,
        inKEVPreviously: 0,
      },
      cpes: [],
      dateCreated: '2023-12-17T18:14:02.695Z',
      dateModified: '2024-10-11T08:05:16.760Z',
      dependencyTree: null,
      derivedEcosystem: 'npm',
      ecosystem: 'npm',
      fullyQualifiedName: 'npm',
      hasFlatHierarchyGraph: false,
      hasNamespace: false,
      hashes: [],
      imageId: null,
      isDeleted: false,
      isVulnerable: true,
      lastVulnerabilityScan: null,
      licenseIds: [],
      licenses: [],
      metadata: null,
      name: 'npm',
      packageGroup: '',
      packageUrl: 'pkg:npm/npm@1.0.1',
      purlParts: {
        qualifiers: {},
        ecosystem: 'npm',
        namespace: '',
        name: 'npm',
        version: '1.0.1',
        subpath: '',
        rawPurl: 'pkg:npm/npm@1.0.1',
        vendorName: '',
        vendorNameAliases: [],
        validation: {
          isValid: false,
          validationErrors: [
            'PURL does not follow general structure. See PURL specification to ensure your package URLs are valid.',
          ],
        },
      },
      references: [],
      relationshipToOrg: null,
      repoTags: null,
      riskScore: 'low',
      type: 'application',
      uploadByVendor: null,
      vendorName: [],
      vendorSourceSbom: null,
      versionMajor: 1,
      versionMinor: 0,
      versionPatch: 1,
      checksum: [
        'ea36a12c93605ca49094d38d6f21d51fb3e56f161f2d91d4a813fb62d9327b514a50dfefdfe26c6cafbd7b92ccc0b38241417ab79dbfd15a905c13d29db5b04e',
        'fe6e34c3ccffb31b0e1f3b755e255c898b7040ed400577cff0642c6f48140311f58dc0ce2a696f681fea1ce646226c393e84d9591860c73bede9eccc303a499d',
        '86337c94665c3508f39d51fdf7324ea5069ef1c7ee07076fecd5ed9e438d41279cfa57ee075031fbcb3abc90f4fa1c31d2fcfca4a6949cdedc00baf560e2c7dc',
        '897749aa3f91dcea4c961d89d8f986fb8dec1764859a3de8b15f11d86172aacab8e93125ed300353d307abb256d2dc15de90b2090332b212c7719e0785d24978',
      ],
    },
  ],
  vulnerabilities: [
    {
      _id: '636f6133260542146bcc94f5',
      fields: {
        _id: '636f6133260542146bcc94f5',
        cveId: 'CVE-2020-28458',
        aliasIds: [],
        aliases: [
          'CVE-2020-28458',
          'GHSA-M7J4-FHG6-XF5V',
          'SNYK-JAVA-ORGWEBJARSBOWER-1051961',
        ],
        credits: [],
        cvss2BaseScore: 7.5,
        cvss2BaseSeverity: 'HIGH',
        cvss2Data: {
          version: 2,
          vectorString: 'AV:N/AC:L/Au:N/C:P/I:P/A:P',
          accessVector: 'NETWORK',
          accessComplexity: 'LOW',
          authentication: 'NONE',
          confidentialityImpact: 'PARTIAL',
          integrityImpact: 'PARTIAL',
          availabilityImpact: 'PARTIAL',
          baseScore: 7.5,
        },
        cvss3BaseScore: 7.3,
        cvss3BaseSeverity: 'HIGH',
        cvss3Data: {
          version: '3.1',
          vectorString: 'CVSS:3.1/AV:N/AC:L/PR:N/UI:N/S:U/C:L/I:L/A:L',
          attackComplexity: 'LOW',
          privilegesRequired: 'NONE',
          userInteraction: 'NONE',
          scope: 'UNCHANGED',
          confidentialityImpact: 'LOW',
          integrityImpact: 'LOW',
          availabilityImpact: 'LOW',
          baseScore: 7.3,
          baseSeverity: 'HIGH',
        },
        dateCreated: '2022-11-12T09:00:15.607Z',
        dateModified: '2024-11-28T07:42:22.351Z',
        epssPercentile: 0.74561,
        epssScore: 0.00408,
        references: [
          {
            type: 'link',
            url: 'https://github.com/DataTables/DataTablesSrc/commit/a51cbe99fd3d02aa5582f97d4af1615d11a1ea03',
          },
        ],
        description:
          'datatables.net vulnerable to Prototype Pollution due to incomplete fix',
        publishDate: '2020-12-16T14:15:12.130Z',
        recommendedAction: 'monitor',
        listingLastModified: '2024-06-21T22:15:16.540Z',
        status: 'PUBLISHED',
        mitigation: [
          {
            'pkg:npm/datatables.net': {
              fixedVersions: ['1.10.22'],
              lastAffectedVersions: [],
            },
          },
        ],
        priorityScore: 318046,
        preferredScore: 7.3,
        preferredSeverity: 'HIGH',
      },
      score: 0,
    },
  ],
};

export const GLOBAL_SEARCH_RESPONSE: GlobalSearchResponse = {
  success: true,
  queryInfo: {
    page: 1,
    limit: 4,
    totalReturn: 63763,
    totalCount: 63763,
    isGrouped: true,
    productsLength: 0,
    assetsLength: 4,
    componentsLength: 63755,
    vulnerabilitiesLength: 4,
  },
  errors: [],
  data: GLOBAL_SEARCH_RESPONSE_DATA,
};

export const GLOBAL_SEARCH_WITH_SINGLE_ITEM_PER_SECTION_STUB = mapGlobalSearchResults({
  products: GLOBAL_SEARCH_RESPONSE_DATA.products?.[0]
    ? [GLOBAL_SEARCH_RESPONSE_DATA.products[0]]
    : [],
  assets: GLOBAL_SEARCH_RESPONSE_DATA?.assets![0]
    ? [GLOBAL_SEARCH_RESPONSE_DATA.assets[0]]
    : [],
  components: GLOBAL_SEARCH_RESPONSE_DATA.components?.[0]
    ? [GLOBAL_SEARCH_RESPONSE_DATA.components![0]]
    : [],
  vulnerabilities: GLOBAL_SEARCH_RESPONSE_DATA.vulnerabilities?.[0]
    ? [GLOBAL_SEARCH_RESPONSE_DATA.vulnerabilities[0]]
    : [],
});
