import { MRT_Row } from 'mantine-react-table';
import { Dispatch, SetStateAction } from 'react';
import { InterfaceFullAsset } from '../../../../types/FullInterfaces';
import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import Labels from '../../../Labels';
import styles from '../AssetsTable.module.scss';

type SupportedEntity = InterfaceFullAsset | InterfaceOrganizationProduct;

export const LabelsColumn = <T extends SupportedEntity>({
  row,
  onLabelClick,
  hasWriteAccess,
}: {
  row: MRT_Row<T>;
  onLabelClick?: (labelId: string) => void;
  hasWriteAccess: boolean;
}) => {
  return (
    <div
      className={`${row.original?.isActive === false ? styles.isInactiveAssetRow : ''}`}
    >
      <Labels
        entity={row.original}
        condensed
        justifyContent="flex-end"
        {...(hasWriteAccess && {
          onLabelClick: (label) => onLabelClick?.(label._id),
        })}
        rounded
      />
    </div>
  );
};
