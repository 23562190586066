import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';
import { ActionIcon, Input, Loader } from '@mantine/core';
import clsx from 'clsx';
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import './BasicSearch.scss';

interface IBasicSearchProps {
  handleSearch?: (searchQuery: string) => void;
  placeholder?: string;
  minCharCount?: number;
  disabled?: boolean;
  initialValue?: string;
  debounceTime?: number;
  isLoading?: boolean;
  onClick?: () => void;
  onClear?: () => void;
  onChange?: (query: string) => void;
}
/**
 * Basic search component that calls a search callback function when at least n characters are typed in.
 * @param handleSearch Callback function that the component will call to actuallty do the search
 * @param placeholder Optional prop for custom placeholder text
 * @param minCharCount Optional prop that sets when the search will be called. Defaults to min 3 characters
 * @param initialValue Optional prop that sets the inputs initial value
 * @returns
 */
const BasicSearch = forwardRef(
  (
    {
      handleSearch,
      placeholder = '',
      minCharCount = 2,
      disabled,
      initialValue = '',
      debounceTime = 500,
      isLoading,
      onClick,
      onClear,
      onChange,
    }: IBasicSearchProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { t } = useTranslation();
    const [searchInputValue, setSearchInputvalue] = useState(initialValue);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
      setSearchInputvalue(initialValue);
    }, [initialValue]);

    const handleClear = () => {
      setSearchInputvalue('');
      handleSearch?.('');
      onClear && onClear();
      onChange?.('');
    };

    const doSearch = (value: string) => {
      setSearchInputvalue(value);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        onChange?.(value);

        if (value.trim().length >= minCharCount) {
          handleSearch?.(value.trim());
        }
      }, debounceTime);
    };

    return (
      <form>
        <Input
          ref={ref}
          type="text"
          classNames={{
            input: clsx('search-input'),
            rightSection: 'btn-search',
          }}
          placeholder={placeholder || t('search.placeholder')}
          value={searchInputValue}
          onChange={(event) => {
            doSearch(event.target.value);
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              doSearch(searchInputValue);
            }
          }}
          onClick={onClick}
          disabled={disabled}
          aria-label={t('search.placeholder')}
          rightSection={
            <>
              <ActionIcon
                className="btn-search"
                type="button"
                onClick={() => {
                  onClick && onClick();
                  searchInputValue ? handleClear() : handleSearch?.(searchInputValue);
                }}
              >
                <TextComponent color="primary" className="iconContainer">
                  {isLoading && <Loader data-testid="loading-icon" size="xs" />}
                  {!isLoading && !searchInputValue && (
                    <Icon
                      icon="search"
                      iconSharp
                      aria-label={t('global.search-icon')}
                      data-testid="search-icon"
                    />
                  )}
                  {!isLoading && searchInputValue && (
                    <Icon
                      icon="times"
                      iconSharp
                      aria-label={t('global.search-icon')}
                      ata-testid="clear-icon"
                    />
                  )}
                </TextComponent>
              </ActionIcon>
            </>
          }
        />
      </form>
    );
  },
);

export default BasicSearch;
