import { Stack } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import '../../../scss/pages/profile.scss';
import { LanguageSelector } from './LanguageSelector';

export const Profile = () => {
  const { t } = useTranslation();

  return (
    <div className="page-profile">
      <div className="header">{t('page.userSettings.profile.header')}</div>
      <div className="divider" />
      <Stack spacing="sm">
        <form>
          <LanguageSelector />
        </form>
      </Stack>
    </div>
  );
};
