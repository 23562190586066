import { Loader, Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import {
  CvssScore,
  CvssScoreProps,
  extractScoreSeverity,
} from '../../components/CvssScore/CvssScore';
import Icon from '../../components/Icon';
import { ListItemAction } from '../../components/ListItemAction/ListItemAction';
import { ManifestTag } from '../../components/ManifestTag/ManifestTag';
import { useFetchTopVulns } from '../../hooks/queries/useFetchTopVulns';

interface Props {}

export const TopVulnerabilities = ({}: Props) => {
  const { t } = useTranslation();

  const { data: fetchedTopVulns = [], isLoading: isLoadingTopVulns } = useFetchTopVulns();

  return (
    <div className="widget top-vulns anim-slideInLeftShort">
      <h2 className="widget-header icon-right">
        {t('page.dashboard.top-vulns.header')}
        <Tooltip label={t('page.dashboard.ordering-tooltip')} position="top" withArrow>
          <span>
            <Icon icon="info-circle" iconSharp />
          </span>
        </Tooltip>
      </h2>

      {isLoadingTopVulns ? (
        <Loader />
      ) : fetchedTopVulns ? (
        <ul className="top-list">
          {fetchedTopVulns.map((vuln) => {
            let { score, severity } = extractScoreSeverity(vuln);
            return (
              <ListItemAction
                key={vuln?._id?.toString()}
                clickableRegionProps={{
                  regionLabel: 'Click to View Details',
                  href: `/vulnerability/${vuln?.cveId}`,
                }}
                iconProps={{
                  icon: 'triangle-exclamation',
                  iconStyle: 'far',
                }}
              >
                <div className="top-vulns-detail">
                  <div className="top-row">
                    <h6>{vuln?.cveId}</h6>
                    {vuln?.kevData?.inKEV || vuln?.kevData?.inKEVPreviously ? (
                      <span className="kev-tag-wrapper">
                        <ManifestTag
                          variant={vuln?.kevData?.inKEV ? 'kev' : 'prev-kev'}
                        />
                      </span>
                    ) : null}
                  </div>
                  <div className="bottom-row">
                    <CvssScore
                      score={score}
                      severity={severity as CvssScoreProps['severity']}
                      emptyText={'--'}
                    />
                    <span>
                      Exploitability:{' '}
                      <strong>{`${(
                        Math.round((vuln?.epssScore || 0) * 10000) / 100
                      ).toFixed(2)}%`}</strong>
                    </span>
                  </div>
                </div>
              </ListItemAction>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};
