import {
  TextComponent,
  TextComponentProps,
} from '@manifest-cyber/design-system/lib/components/TextComponent';
import capitalizeWords from '../../lib/capitalizeWords';
import style from './CvssScore.module.scss';

export type Severity = 'CRITICAL' | 'HIGH' | 'MEDIUM' | 'LOW';
export interface CvssScoreProps {
  score?: number | null;
  severity?: Severity | null;
  emptyText?: string;
  uncolored?: boolean;
  textVariant?: TextComponentProps['variant'];
}

export type LowercaseSeverity = Lowercase<Exclude<Severity, null>>;

export interface VulnCvssData {
  cvss3BaseScore?: number | null;
  cvss3BaseSeverity?: string | null;
  cvss2BaseScore?: number | null;
  cvss2BaseSeverity?: string | null;
  cvssScore?: number | null;
  severity?: string | null;
  preferredScore?: number | Number | null;
  preferredSeverity?: string | null;
}

const isValidScore = (score?: number | Number | null): boolean => {
  if (score != undefined && score != null && !isNaN(score as number)) {
    return true;
  }
  return false;
};

/**
 * Extracts the score and severity from the given VulnCvssData object
 *
 * @param vulnerability V
 * @returns {score: number | null, severity: string | null}
 */
export const extractScoreSeverity = (
  vulnerability: VulnCvssData,
): {
  score: number | null;
  severity: string | null;
} => {
  let score: number | null = null;
  if (isValidScore(vulnerability.cvss2BaseScore)) score = vulnerability.cvss2BaseScore!;
  if (isValidScore(vulnerability.cvss3BaseScore)) score = vulnerability.cvss3BaseScore!;
  if (isValidScore(vulnerability.cvssScore)) score = vulnerability.cvssScore!;
  if (isValidScore(vulnerability.preferredScore))
    score = vulnerability.preferredScore as number;
  const severity =
    vulnerability.preferredSeverity ||
    vulnerability.severity ||
    vulnerability.cvss3BaseSeverity ||
    vulnerability.cvss2BaseSeverity ||
    null;
  return { score, severity };
};

interface ParseSeverityAndScoreParams {
  severity?: string | null;
  score?: number | null;
  emptyText: string;
}

export const parseSeverityAndScore = ({
  severity,
  score,
  emptyText,
}: ParseSeverityAndScoreParams) =>
  !score || !severity
    ? emptyText
    : `${capitalizeWords(severity?.toLowerCase() ?? '')} (${score?.toFixed(1) ?? ''})`;

export const CvssScore = ({
  uncolored = false,
  emptyText = '--',
  score,
  severity,
  textVariant,
}: CvssScoreProps) => {
  const classColorReference: LowercaseSeverity | 'none' =
    uncolored || !severity ? 'none' : (severity.toLowerCase() as LowercaseSeverity);

  const label = parseSeverityAndScore({
    score,
    severity,
    emptyText,
  });

  if (!score || !severity) {
    return (
      <TextComponent variant={textVariant} color="muted">
        {label}
      </TextComponent>
    );
  }

  return <span className={style[classColorReference]}>{label}</span>;
};
