import clsx from 'clsx';
import { useRef } from 'react';
import styles from './ProductIcon.module.scss';

export const ProductIcon = ({
  height = 16,
  width = 16,
  className,
}: {
  height?: number;
  width?: number;
  className?: string;
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  return (
    <svg
      ref={svgRef}
      className={clsx('manifest-icon', styles.icon, className)}
      width={width}
      height={height}
      viewBox="2.666749954223633 1.5 10.66664981842041 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M2.66675 6.42105L6.93341 8.94737V14L2.66675 11.4737V6.42105Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6223 4.52632L8.00008 7.05263L3.37786 4.52632L8.00008 2L12.6223 4.52632Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3334 11.4737L9.06675 14V8.94737L13.3334 6.42105V11.4737Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
