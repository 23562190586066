/**
 * Prevent typos and use these constants to reference feature flags.
 */
export const featureFlagDisciminators = {
  sbomUploadAlerts: 'sbomUploadAlerts',
  // Add'l UI elements to help make troubleshooting a bit faster
  engineerElements: 'engineerElements',
  githubMultiOrg: 'githubMultiOrg',
  sharingPortal: 'sharingPortal',
  productsInGlobalSearch: 'productsInGlobalSearch',
} as const;

export type FeatureFlag =
  (typeof featureFlagDisciminators)[keyof typeof featureFlagDisciminators];

export default featureFlagDisciminators;
