import { InterfaceVulnerability } from '@manifest-cyber/types/interface/dbTables';
import { Popover } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import ClickableRegion from '../../../../components/ClickableRegion';
import {
  CvssScore,
  CvssScoreProps,
  extractScoreSeverity,
} from '../../../../components/CvssScore/CvssScore';
import Icon from '../../../../components/Icon';
import capitalizeWords from '../../../../lib/capitalizeWords';

interface Props {
  vuln: InterfaceVulnerability;
}
function RiskyComponentVulnPopover({ vuln }: Props) {
  const { t } = useTranslation();
  const [cvePopoverOpened, { close: closeCvePopover, open: openCvePopover }] =
    useDisclosure(false);

  const ClickableRegionRef = forwardRef<HTMLDivElement>((props, ref) => (
    <div ref={ref} className="cve-link">
      <ClickableRegion href={`/vulnerability/${vuln?.cveId?.toString()}`}>
        <Icon
          icon={
            vuln?.recommendedAction === 'mitigate'
              ? 'triangle-exclamation'
              : vuln?.recommendedAction === 'monitor'
                ? 'circle-exclamation'
                : 'circle-exclamation'
          }
        />
        {vuln?.cveId}
      </ClickableRegion>
    </div>
  ));

  function renderCvss(): React.ReactNode {
    const { score, severity } = extractScoreSeverity(vuln);
    return (
      <CvssScore
        score={score}
        severity={severity as CvssScoreProps['severity']}
        emptyText={'--'}
      />
    );
  }

  function getEpssSeverity(percentile: number): string {
    if (percentile >= 80) {
      return t('jargon.severity.critical');
    } else if (percentile >= 60 && percentile < 80) {
      return t('jargon.severity.high');
    } else if (percentile >= 40 && percentile < 60) {
      return t('jargon.severity.medium');
    } else if (percentile < 40) {
      return t('jargon.severity.low');
    } else {
      return t('jargon.severity.unknown');
    }
  }

  function renderExploitability(): React.ReactNode {
    if (vuln) {
      const { epssPercentile, epssScore } = vuln;
      const epssData = {
        exploitability: '--',
        percentile: '--',
        severity: 'Unknown',
      };
      if (epssPercentile || epssPercentile === 0) {
        const percentile = Math.round(epssPercentile * 100);
        const severity = getEpssSeverity(percentile);
        epssData.percentile = percentile.toString() + '%';
        epssData.severity = severity;
      }
      if (epssScore || epssScore === 0) {
        const exploitability = Math.round(epssScore * 100);
        epssData.exploitability = exploitability.toString() + '%';
      }
      return (
        <>
          <span
            className={`${epssData.severity.toLowerCase()}`}
          >{`${epssData.severity} `}</span>
          <span>{`(${epssData.percentile})`}</span>
        </>
      );
    }
    return <>--</>;
  }
  return (
    <Popover
      width={228}
      position="top-start"
      withArrow
      shadow="md"
      opened={cvePopoverOpened}
    >
      <Popover.Target>
        <div
          onMouseEnter={openCvePopover}
          onMouseLeave={closeCvePopover}
          className={`cve ${
            vuln?.recommendedAction === 'mitigate'
              ? 'mitigate'
              : vuln?.recommendedAction === 'monitor'
                ? 'monitor'
                : ''
          } `}
        >
          <ClickableRegionRef />
        </div>
      </Popover.Target>
      <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
        <div className="cve-popover-content">
          <div className="cve-popover-header">{vuln?.cveId}</div>
          <div className="cve-popover-body">
            <div className="cve-popover-body-row">
              <div className="header-row">{t('global.recommendation')}</div>
              <div className={`cve-popover-body-row-value ${vuln.recommendedAction}`}>
                {vuln?.recommendedAction
                  ? capitalizeWords(vuln.recommendedAction)
                  : t('global.na')}
              </div>
            </div>
            <div className="cve-popover-body-row">
              <div className="header-row">{t('global.cvss')}</div>
              <div className="cve-popover-body-row-value">{renderCvss()}</div>
            </div>
            <div className="cve-popover-body-row">
              <div className="header-row">{t('global.epss')}</div>
              <div className="cve-popover-body-row-value">{renderExploitability()}</div>
            </div>
          </div>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}

export default RiskyComponentVulnPopover;
