import { Select, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { captureExceptionWithMessage } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

export const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const handleLanguageChange = (value: string) => {
    setLanguage(value);
    i18n.changeLanguage(value).catch((error) => {
      captureExceptionWithMessage('Error changing language:', error);
    });
  };

  useEffect(() => {
    const handleLanguageChanged = (language: string) => setLanguage(language);

    i18n.on('languageChanged', handleLanguageChanged);

    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [i18n]);

  return (
    <>
      <Text id="language-label">{t('page.userSettings.profile.language')}</Text>
      <Select
        aria-labelledby="language-label"
        placeholder={t('page.userSettings.profile.pick-one')}
        data={[
          { value: 'en-US', label: '🇺🇸 English' },
          { value: 'it', label: '🇮🇹 Italian' },
          { value: 'ja', label: '🇯🇵 日本語' },
          { value: 'de', label: '🇩🇪 Deutsch' },
        ]}
        required
        value={language}
        onChange={handleLanguageChange}
        withinPortal={false} // Ensure options render within the DOM tree
      />
    </>
  );
};
