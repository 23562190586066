import React, { ReactElement } from 'react';
import { Header, Text } from '@mantine/core';
import styles from './TopBanner.module.scss';

interface TopBannerProps {
  text: ReactElement;
}

export const TopBanner = ({ text }: TopBannerProps) => {
  return (
    <Header fixed height={43} className={styles.topBanner}>
      <Text color="white" weight={500}>
        {text}
      </Text>
    </Header>
  );
};
