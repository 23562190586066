import { InterfaceVulnerability } from '@manifest-cyber/types/interface/dbTables';
import { Button, List, Spoiler, Tabs } from '@mantine/core';
import { DateTime } from 'luxon';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import capitalizeWords from '../../lib/capitalizeWords';
import ClickableRegion from '../ClickableRegion';
import { CvssScore, CvssScoreProps, extractScoreSeverity } from '../CvssScore/CvssScore';
import Icon from '../Icon';
import TriageForm from './TriageForm';

interface Props {
  cve: InterfaceVulnerability;
  updateIsCloseable: Dispatch<SetStateAction<boolean>>;
  shouldResetForm: boolean;
  organizationAssetId: string;
  handleTriageSaved: () => void;
}
function SingleCVETriage({
  cve,
  organizationAssetId,
  updateIsCloseable,
  shouldResetForm,
  handleTriageSaved,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>('triageState');

  const getColorClassFromRecommandation = (recommandation: string) => {
    switch (recommandation) {
      case 'mitigate':
        return 'critical';
      case 'monitor':
        return 'medium';
      case 'accept':
        return 'low';
      default:
        return 'info';
    }
  };
  const getRecommendation = () => {
    if (cve.recommendedAction) {
      if (cve.recommendedAction === 'accept') {
        return t('jargon.recommendation.acceptRisk');
      }
      return t(`jargon.recommendation.${cve.recommendedAction}`);
    }
    return t('global.notProvided');
  };
  const renderSeverity = () => {
    const { severity, score } = extractScoreSeverity(cve);

    return (
      <div className={`value pop `}>
        <CvssScore
          score={score}
          severity={severity as CvssScoreProps['severity']}
          emptyText={t('global.notProvided')}
        />
      </div>
    );
  };
  const renderEpss = (value: number | null | undefined) => {
    const percent =
      value && value < 1
        ? Math.round((value * 10000) / 100)
        : Math.round(value || 0)
          ? Math.round(value || 0)
          : 0;
    const displayValue = percent || percent === 0 ? `${percent}%` : null;

    return (
      <div className={`value pop`}>
        {displayValue && displayValue.length > 1 ? (
          <div className="severity">{capitalizeWords(`${displayValue}`)}</div>
        ) : (
          <span className="na">{t('global.notProvided')}</span>
        )}
      </div>
    );
  };

  const renderAffectedComponents = () => {
    //@ts-ignore
    const { affectedComponents } = cve;
    if (affectedComponents && affectedComponents.length > 0) {
      return (
        <div className="value">
          <Spoiler
            maxHeight={250}
            showLabel={`+ ${affectedComponents.length - 10} ${t('global.components')}`}
            hideLabel={t('global.seeLess')}
          >
            <List listStyleType="disc">
              {affectedComponents.map(
                (component: { id: string; affectedComponentName: string }) => {
                  const displayValue =
                    component.affectedComponentName.length > 35
                      ? component.affectedComponentName.substring(0, 35) + '...'
                      : component.affectedComponentName;
                  return (
                    <List.Item key={component.id}>
                      <ClickableRegion
                        regionLabel={
                          displayValue !== component.affectedComponentName
                            ? component.affectedComponentName
                            : ''
                        }
                        target="_blank"
                        className="table-link"
                        href={`/component/${component.id}/overview`}
                      >
                        {displayValue}
                      </ClickableRegion>
                    </List.Item>
                  );
                },
              )}
            </List>
          </Spoiler>
        </div>
      );
    }
  };

  const renderLink = () => {
    const { references } = cve;
    const advisoryLink = references?.find(
      (ref) => ref?.url?.includes('https://nvd.nist.gov/') || ref.type === 'ADVISORY',
    );
    const link = { url: advisoryLink?.url, type: advisoryLink?.type };
    if (!link.url) {
      link.url = references?.find((ref) => ref?.url)?.url;
      link.type = '';
    }
    return (
      <ClickableRegion regionLabel={link.type} target="_blank" href={link.url}>
        {link.type === 'ADVISORY' ? t('global.nvd') : link.url}
      </ClickableRegion>
    );
  };

  return (
    <div className="single-triage">
      <Tabs value={activeTab} onTabChange={(value: string) => setActiveTab(value)}>
        <Tabs.List>
          <Tabs.Tab value="triageState">{t('triage.triageState')}</Tabs.Tab>
          <Tabs.Tab value="details">{t('global.details')}</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="triageState">
          <TriageForm
            cves={[cve]}
            organizationAssetId={organizationAssetId}
            updateIsCloseable={updateIsCloseable}
            shouldResetForm={shouldResetForm}
            onSave={handleTriageSaved}
          />
        </Tabs.Panel>
        <Tabs.Panel value="details">
          <div className="details-container">
            <div className="buttons-container">
              <div className="item">
                <Button
                  variant="default"
                  className="button"
                  rightIcon={<Icon icon="arrow-up-right-from-square" />}
                  onClick={() =>
                    window.open(
                      `https://www.google.com/search?q=${cve.cveId}`,
                      '_blank',
                      'rel=noopener noreferrer',
                    )
                  }
                >
                  {t('global.google')}
                </Button>
              </div>
              <div className="item">
                <Button
                  variant="default"
                  className="button"
                  rightIcon={<Icon icon="arrow-up-right-from-square" />}
                  onClick={() =>
                    window.open(
                      `/vulnerability/${cve.cveId}`,
                      '_blank',
                      'rel=noopener noreferrer',
                    )
                  }
                >
                  {t('global.moreInfo')}
                </Button>
              </div>
            </div>
            <div className="info-container">
              <div className="row">
                <div className="item">
                  <div className="label">{t('global.recommendation')}</div>
                  <div
                    className={`value pop ${getColorClassFromRecommandation(
                      cve.recommendedAction || '',
                    )}`}
                  >
                    {getRecommendation()}
                  </div>
                </div>
                <div className="item">
                  <div className="label">{t('jargon.severity.label')}</div>
                  {renderSeverity()}
                </div>
              </div>
              <div className="row">
                <div className="item">
                  <div className="label">{t('jargon.exploitability.epssPercentile')}</div>
                  {renderEpss(cve.epssPercentile)}
                </div>
                <div className="item">
                  <div className="label">{t('jargon.exploitability.epssScore')}</div>
                  {renderEpss(cve.epssScore)}
                </div>
              </div>
              <div className="row">
                <div className="item">
                  <div className="label">
                    {t('vulnerabilities.table.columns.affectedComponents')}
                  </div>
                  {renderAffectedComponents()}
                </div>
              </div>
              <div className="row">
                <div className="item">
                  <div className="label">
                    {t('vulnerabilities.table.columns.publishDate')}
                  </div>
                  <div className="value">
                    {cve.publishDate
                      ? DateTime.fromISO(cve.publishDate as unknown as string).toFormat(
                          'MM/dd/yyyy',
                        )
                      : t('global.notProvided')}
                  </div>
                </div>
                <div className="item">
                  <div className="label">
                    {t('vulnerabilities.table.columns.lastUpdated')}
                  </div>
                  <div className="value">
                    {cve.dateModified
                      ? DateTime.fromISO(cve.dateModified as unknown as string).toFormat(
                          'MM/dd/yyyy',
                        )
                      : t('global.notProvided')}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="item">
                  <div className="label">{t('global.links')}</div>
                  <div className="value">{renderLink()}</div>
                </div>
              </div>
            </div>
          </div>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}

export default SingleCVETriage;
