import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

interface UserAgreementAcceptanceResponse {
  data: {
    agreementAcceptedAt?: string;
  };
  success: boolean;
  errors: string[];
}

const fetchUserAgreementAcceptance =
  async (): Promise<UserAgreementAcceptanceResponse> => {
    return await AxiosProxy.get({ url: 'organization/member/agreement' });
  };

export const useUserAgreementAcceptance = () => {
  return useQuery<UserAgreementAcceptanceResponse, unknown>({
    queryKey: ['userAgreementAcceptance'],
    queryFn: fetchUserAgreementAcceptance,
    onError: (error) => {
      captureExceptionWithMessage('Error fetching user agreement acceptance', error);
    },
    retry: 3,
  });
};
