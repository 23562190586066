export const useProductVulnerabilitiesTab = () => {
  const getEPSSScore = (value: number) => {
    /**
     * None – 0.0
     Low – 0.1 – 3.9
     Medium – 4.0 – 6.9
     High 7.0 – 8.9
     Critical – 9.0 – 10.0
     */
    if (value >= 0 && value < 0.01) {
      return (
        <>
          <span>{(value * 100).toFixed(1)}%</span>
        </>
      );
    } else if (value >= 0.01 && value < 0.4) {
      return (
        <>
          <span>{(value * 100).toFixed(1)}%</span>
        </>
      );
    } else if (value >= 0.4 && value < 0.7) {
      return (
        <>
          <span>{(value * 100).toFixed(1)}%</span>
        </>
      );
    } else if (value >= 0.7 && value < 0.9) {
      return (
        <>
          <span>{(value * 100).toFixed(1)}%</span>
        </>
      );
    } else if (value >= 0.9 && value <= 1) {
      return (
        <>
          <span>{(value * 100).toFixed(1)}%</span>
        </>
      );
    }
  };

  return {
    getEPSSScore,
  };
};
